



















import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FileType from '@/utils/enums/FileType';
import { namespace } from 'vuex-class';
import EntityType from '@/utils/enums/EntityType';
import Handout from '@/models/graphql/Handout';
import { Data } from '@/utils/types/WidgetData';
import Session from '@/models/graphql/Session';
import ResourcesFormComponent from '@/components/ResourcesFormComponent.vue';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import Speaker from '@/models/graphql/Speaker';

const handoutStore = namespace('HandoutStore');

@Component({
  components: {
    ResourcesFormComponent,
  },
})
export default class ResourcesFormWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'ResourcesFormWidgetStore';

  @Prop({
    required: false,
    default: null,
  })
  private readonly type!: string;

  @Prop({ required: false })
  private readonly entityCode!: string;

  @Prop({ required: false })
  private readonly data!: Data[];

  @handoutStore.Action
  private deleteHandout!: (uid: string) => Promise<boolean>;

  @handoutStore.Action
  private createOrUpdateHandoutForEntity!: (payload: {
    entityId: string;
    entityType: string;
    handout: Partial<Handout>;
  }) => Promise<Handout | undefined>;

  private fileType = `${FileType.PDF},${FileType.DOC},${FileType.DOCX},${FileType.XLS},
        ${FileType.XLSX},${FileType.PPT},${FileType.PPTX}`;

  private assets: Handout[] = [];

  private get entityUid(): string {
    switch (this.type) {
      case EntityType.SPEAKER:
        if (this.authUser.speakers) {
          return this.authUser.speakers[0].uid;
        }
        break;
      case EntityType.SESSION:
        if (this.data.length > 0) {
          // eslint-disable-next-line no-case-declarations
          const session = this.data[0] as unknown as Session;
          if (session) return session.uid;
        }
        break;
      default:
        return '';
    }
    return '';
  }

  created(): void {
    this.setDataConfig();
    this.updateAssets();
  }

  @Watch('data')
  updateAssets(): void {
    switch (this.type) {
      case EntityType.SPEAKER:
        if (this.authUser.speakers && this.authUser.speakers.length > 0) {
          Object.assign(this.authUser.speakers[0], this.data[0] as unknown as Speaker);
          this.assets = this.authUser.speakers[0].handouts;
        }
        break;
      case EntityType.SESSION:
        if (this.data.length > 0) {
          // eslint-disable-next-line no-case-declarations
          const { handouts } = this.data[0] as unknown as Session;
          if (handouts) this.assets = (this.data[0] as unknown as Session).handouts || [];
        }
        break;
      default:
        break;
    }
  }
}
